<template>
  <el-dialog title="选择公司" append-to-body :visible.sync="dialogVisible" @open="open" width="728px">
    <div class="head">
      <el-input v-model="params.companyName" size="small" style="width:610px;margin-right:20px" @keydown.enter.native="search">
        <el-button type="primary" @click="search" slot="append" icon="el-icon-search">查询</el-button>
      </el-input>
      <el-button size="small" @click="reset">重置</el-button>
    </div>
    <div class="body">
      <div class="left">

        <ul class="list infinite-list-wrapper" style="height:100%;overflow:auto" v-infinite-scroll="load" :infinite-scroll-disabled="status==='nomore'" :infinite-scroll-immediate="false">
          <li v-for="item in list" :key="item.id">
            <el-checkbox v-model="item.checked" @change="checkChange($event,item)">{{item.companyName}}</el-checkbox>
          </li>
          <p class="status" v-if="list.length>5 && status==='loading'">加载中...</p>
          <p class="status" v-if=" list.length>5 && status==='nomore'">-没有更多了-</p>
        </ul>

      </div>
      <div class="right ">
        <ul style="overflow:auto;height:100%">
          <li v-for="(item,index) in chooseList" :key="item.tenantCode">
            {{item.companyName}}
            <i class="el-icon-close" @click="clearRow(item,index)"></i>
          </li>

        </ul>
        <span class="clear" @click="clear">清除所有</span>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "valueList",
    event: "change"
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    valueList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      list: [],
      params: {
        page: 1,
        limit: 20,
        companyName: ''
      },
      count: 10,
      status: 'loadmore',
      typeList: [],
      activeType: {
        id: null
      },
      chooseList: []
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },


  },
  created() {

  },
  methods: {
    clearRow(item, index) {
      var row = this.list.find(ele => {
        return ele.tenantCode === item.tenantCode
      })
      if (row) row.checked = false;
      this.chooseList.splice(index, 1);
    },
    clear() {
      this.chooseList = [];
      this.list.forEach(item => {
        item.checked = false
      })
    },
    checkChange(bol, item) {


      /* 先判断已选择的列表有没有当前数据 */
      var index = -1;
      for (var i = 0; i < this.chooseList.length; i++) {
        console.log(item, this.chooseList[i])
        if (item.tenantCode === this.chooseList[i].tenantCode) {
          index = i;
          break
        }
      }
      if (bol && index < 0) {
        this.chooseList.push(item)
      }
      if (!bol && index >= 0) {
        this.chooseList.splice(index, 1)
      }
    },
    confirm() {
      let list = this.chooseList.map(item => {
        return {
          tenantCode: item.tenantCode,
          companyName: item.companyName
        }
      })
      this.$emit('change', list);
      this.$emit('update:show', false);
    },
    setChecked() {
      this.list.forEach(item => {
        let row = this.chooseList.find(ele => {
          return ele.tenantCode === item.tenantCode
        })
        item.checked = row ? true : false;

      })
    },
    reset() {
      this.params.companyName = '';
      this.choose = {}
      this.search();
    },
    clickType(item) {
      this.activeType = item;
      this.search();
    },
    load() {

      this.params.page++;
      this.getList()
    },
    open() {
      this.chooseList = JSON.parse(JSON.stringify(this.valueList));
      this.setChecked()
      this.reset();

    },

    search() {
      this.params.page = 1;
      this.list = [];
      this.status = 'loadmore';
      this.getList();
    },
    /* 获取列表 */
    getList() {
      this.$get('/platform-admin/tenant/page', this.params).then(res => {
        //判断是否是最后一页
        var list = res.data.list || []
        if (list.length < this.params.limit) {
          this.status = 'nomore';
        } else {
          this.status = 'loadmore';
        }
        this.list = this.list.concat(list);
        this.setChecked()
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.head {
  margin-top: 20px;
}
.body {
  height: 420px;
  display: flex;
  padding: 20px 0;
  > div {
    border: 1px solid #eee;
  }
  .left {
    flex: 1;
    li {
      padding: 5px 15px;
    }
    .status {
      text-align: center;
      padding: 5px 0;
      color: #999;
    }
  }
  .right {
    flex: 1;
    margin-left: 20px;
    padding-bottom: 30px;
    position: relative;
    .clear {
      color: #409eff;
      bottom: 10px;
      right: 15px;
      position: absolute;
      cursor: pointer;
      user-select: none;
    }
    li {
      padding: 5px 15px;
      border: 1px solid #fff;
      i {
        color: #409eff;
        vertical-align: middle;
        float: right;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
