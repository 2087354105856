<template>
  <el-dialog v-dialogDrag :title="title" @open="open" :visible.sync="visible" width="750px">
    <el-form class="form" ref="form" :model="form" :rules="rules" label-width="110px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <el-input v-model="form.content" type="textarea" :rows="5"></el-input>
      </el-form-item>
      <el-form-item label="推送范围" prop="rangeState">
        <label>
          <el-radio v-model="form.rangeState" :label="0">全体公司</el-radio>
        </label>
        <label class="label" @click="chooseShow=true">
          <el-radio v-model="form.rangeState" :label="1">
            <div class="chooseInput">
              <span class="placeholder" v-if="!form.scope || !form.scope.length">请选择</span>
              <span v-if="form.scope && form.scope.length" :title="form.scope.map(item=>item.companyName).join(',')">
                {{form.scope.map(item=>item.companyName).join(',')}}
              </span>
            </div>
          </el-radio>
        </label>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show',false)">取 消</el-button>
      <el-button size="small" type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
    <Choose :show.sync="chooseShow" v-model="form.scope" />
  </el-dialog>
</template>

<script>
import Choose from "./choose.vue"
export default {
  components: { Choose },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String | Number,
      default: '',
    },
  },
  data() {
    return {
      form: {
        title: '',
        content: '',
        rangeState: 0,
        scope: []
      },
      chooseShow: false,
      loading: false,
      editor: null,
      rules: {
        title: [{ required: true, message: "标题必填" }],
        content: [{ required: true, message: "内容必填" }],
      },
    };
  },

  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
    title() {
      var title = "新增系统公告";
      if (this.form.id) {
        title = "修改系统公告";
      }
      return title;
    },
  },
  watch: {

  },
  created() {

  },
  mounted() { },
  methods: {
    getCompany(list) {
      console.log(list)
      this.form.scope = list.map(item => {
        return {
          tenantCode: item.tenantCode,
          companyName: item.companyName
        }
      })
    },
    open() {
      if (this.$refs.form) this.$refs.form.resetFields();
      if (this.id) {
        this.$get(`/platform-config/systemnotice/${this.id}`).then(res => {
          this.form = res.data;
          this.form.scope = this.form.scope || []
        })
      } else {
        this.form = {
          title: '',
          content: '',
          rangeState: 0,
          scope: []
        }
      }
    },

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;

          this[this.id ? '$put' : '$post']('/platform-config/systemnotice', this.form).then(res => {
            if (res && res.code === 0) {
              this.$message.success('保存成功');
              this.$emit(this.id ? 'edit' : 'add');
              this.$emit('update:show', false)
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })

    },
  },
};
</script>

<style scoped lang="scss">
.editor {
  width: 100%;
  height: 350px;
  padding-bottom: 20px;
}
.form {
  padding: 20px;
}
.label {
  margin-left: 30px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}
.chooseInput {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
  vertical-align: middle;
  .placeholder {
    color: #999;
  }
}
</style>